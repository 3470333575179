import React, { useEffect, useState } from "react";
import axios from "axios";

import Preloader from "../components/preloader";

import "../scss/dashboard.scss";

const Dasbboard = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onTokenEdit = async (e) => {
    const token = e.target.value.trim();

    if (token.length === 6) {
      setError(null);
      setLoading(true);
      try {
        const response = await axios.post(
          "//ncmd-totalmd.herokuapp.com/totalmdcare/fetch",
          {
            token,
          }
        );
        setData(response.data.data.reverse());
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    } else {
      setError(null);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center flex-column dashboard-container">
      {loading && <Loader />}
      {data.length === 0 && (
        <div className="input-container">
          <input
            type="text"
            pattern="\d*"
            placeHolder="Enter 6 digit PIN"
            maxlength="6"
            onChange={onTokenEdit}
          />
        </div>
      )}
      {error && <h3 className="error">{error.message} </h3>}{" "}
      {data.length > 0 && (
        <div className="table-container">
          <table>
            <tbody>
              <tr>
                <th>Name</th>
                <th>Age</th>
                <th>Insurance</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Timestamp</th>
              </tr>
              {data.map((d) => (
                <tr key={d.id}>
                  <td> {d.name} </td>
                  <td> {d.age} </td>
                  <td> {d.insurance} </td>
                  <td> {d.email} </td>
                  <td> {d.phone} </td>
                  <td> {new Date(d.timestamp).toLocaleString()} </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const Loader = () => (
  <div className="overlay">
    <h3 className="center-text"> Loading...</h3>
  </div>
);

export default Dasbboard;
