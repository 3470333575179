import React from 'react';

import Staff from '../components/staff';
import About from '../components/about';
import Banner from '../components/banner';
import Contact from '../components/contact';
import FunFacts from '../components/fun_facts';
import Insurances from '../components/insurances';
import MainServices from '../components/main_services';

const Home = () => (
  <div className="container col-lg-12 p-0">
    <Banner />
    <MainServices />
    <About />
    <Staff />
    <Insurances />
    <FunFacts />
    <Contact />
  </div>
);

export default Home;
