import React, { useEffect } from 'react';

import '../scss/banner.scss';

const PLAY_NOW = false;

const Banner = () => {
  useEffect(() => {
    window.createCarousel && window.createCarousel();
  }, []);

  return (
    <div className="home-slides owl-carousel owl-theme" id="banner-area">
      <div className="main-banner item-bg1">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="main-banner-content">
                <span>Our Philosophy</span>
                <h1>Your Health is Our Passion</h1>
                <p>
                  Working for our patients to provide comprehensive
                  evidence-based treatment plan regardless their ability to pay
                </p>

                <div className="btn-box">
                  <a href="#appointment-area" className="btn btn-primary">
                    Make appointment <i className="fas fa-bell"></i>
                  </a>
                  {PLAY_NOW && (
                    <a
                      href="https://www.youtube.com/watch?v=bk7McNUjWgw"
                      className="btn btn-light popup-youtube"
                    >
                      Play Now <i className="fas fa-play"></i>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="circle-shape1">
          <img src="/assets/img/circle-shape1.png" alt="image" />
        </div>
        <div className="circle-shape2">
          <img src="/assets/img/circle-shape2.png" alt="image" />
        </div>
        <div className="shape1">
          <img src="/assets/img/shape/1.png" alt="image" />
        </div>
      </div>

      <div className="main-banner item-bg2">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="main-banner-content">
                <span>Best Primary Care Services</span>
                <h1>Personalized care for each individual patient</h1>
                <p>
                  Creating and recommending the treatment options tailored to
                  your healthcare needs.
                </p>

                <div className="btn-box">
                  <a href="#appointment-area" className="btn btn-primary">
                    Make appointment <i className="fas fa-bell"></i>
                  </a>
                  {PLAY_NOW && (
                    <a
                      href="https://www.youtube.com/watch?v=bk7McNUjWgw"
                      className="btn btn-light popup-youtube"
                    >
                      Play Now <i className="fas fa-play"></i>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="circle-shape1">
          <img src="/assets/img/circle-shape1.png" alt="image" />
        </div>
        <div className="circle-shape2">
          <img src="/assets/img/circle-shape2.png" alt="image" />
        </div>
        <div className="shape1">
          <img src="/assets/img/shape/1.png" alt="image" />
        </div>
      </div>

      <div className="main-banner item-bg3">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="main-banner-content">
                <span>Modern Practice</span>
                <h1>Excellent Patient Care with Telemedicine</h1>
                <p>
                  We are proud to offer the telemedicine service to our patients
                  who can not come to office for safety and convenience.
                </p>

                <div className="btn-box">
                  <a href="#appointment-area" className="btn btn-primary">
                    Make appointment <i className="fas fa-bell"></i>
                  </a>
                  {PLAY_NOW && (
                    <a
                      href="https://www.youtube.com/watch?v=bk7McNUjWgw"
                      className="btn btn-light popup-youtube"
                    >
                      Play Now <i className="fas fa-play"></i>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="circle-shape1">
          <img src="/assets/img/circle-shape1.png" alt="image" />
        </div>
        <div className="circle-shape2">
          <img src="/assets/img/circle-shape2.png" alt="image" />
        </div>
        <div className="shape1">
          <img src="/assets/img/shape/1.png" alt="image" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
