import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { SERVICES } from '../../config';
import RightSideComponent from '../../components/right_side_component';

import '../../scss/cardio_respiratory_health.scss';

const COMMON_ILLNESSES = [
  'Sore throat',
  'Urinary Tract Infection',
  'Cellulitis',
  'Sinus infection',
  'Common Cold',
  'Fever',
  'Vomiting',
  'Rash',
  'Diarrhea',
  'Gastro',
  'esophageal reflux',
  'Medication refills',
  'Headache',
  'Allergies',
];

const TelemedicineAppointments = ({ location }) => {
  return (
    <section class="services-details-area ptb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <div class="services-details-desc">
              <div class="services-details-image">
                <img src="/assets/img/services/s3_3.jpg" alt="image" />
              </div>

              <h3>Telemedicine Appointments</h3>

              <p>
                While the staff at Total MD Care follows strict CDC anti-virus
                guidelines to ensure all visits to our primary care clinic are
                safe, some of our patients prefer the convenience and
                reassurance of remaining at home for their consultation with Dr.
                Patel. To better serve you in these challenging times, we now
                offer telemedicine appointments using your smartphone or
                computer. During these virtual office visits, Dr. Patel can
                assess your current health status and concerns, write
                prescriptions or order tests as needed, and make referrals to
                medical specialists if your particular case requires it. You can
                schedule appointment with Dr. Patel for common illness like
                following
              </p>

              <div class="services-details-features">
                <div class="row">
                  <div class="p-2">
                    <ul class="services-features-list">
                      {COMMON_ILLNESSES.map((_) => (
                        <li key={_}>
                          <i class="flaticon-check-mark"></i> {_}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <h3>Professional Medical Service</h3>
              <p>
                Whether appointments are in-person or virtual, our guiding
                principle is to deliver state-of-the-art healthcare, combined
                with sincere interest and compassion, to each and every patient
                we serve. We are using variety of different application to do
                Telemedicine with our patients.
              </p>

              <ul class="wp-block-gallery columns-3">
                <li class="blocks-gallery-item">
                  <figure>
                    <img src="/assets/img/services/s3_1.jpg" alt="image" />
                  </figure>
                </li>

                <li class="blocks-gallery-item">
                  <figure>
                    <img src="/assets/img/services/s3_2.jpg" alt="image" />
                  </figure>
                </li>

                <li class="blocks-gallery-item">
                  <figure>
                    <img src="/assets/img/services/s3_3.jpg" alt="image" />
                  </figure>
                </li>
              </ul>
            </div>
          </div>
          <RightSideComponent location={location} />
        </div>
      </div>
    </section>
  );
};

export default TelemedicineAppointments;
