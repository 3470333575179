import React from 'react';

import { INSURANCES } from '../config';

import '../scss/insurances.scss';

const Insurances = () => {
  return (
    <section class="partner-area ptb-100 bg-f4f9fd">
      <div class="container">
        <div class="section-title">
          <h2>Insurance Partners</h2>
          <p>We accept following insurances</p>
        </div>

        <div class="d-flex justify-content-center customers-partner-list">
          {INSURANCES.map(({ imgUrl, text }) => (
            <div class="m-1 partner-item d-flex align-items-center justify-content-center">
              <a href="#">
                <img src={imgUrl} alt={text} />{' '}
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Insurances;
