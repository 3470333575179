import React from 'react';
import { Link } from 'react-router-dom';

import { STAFF } from '../config';

import '../scss/staff.scss';

const Staff = () => {
  return (
    <section className="doctor-area ptb-100">
      <div className="container">
        <div className="section-title">
          <span>Staff</span>
          <h2>Meet Our Associates</h2>
        </div>

        <div className="row">
          {STAFF.map(
            ({ imgUrl, name, designation, profilePage, socialMedias }) => (
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="single-doctor-box">
                  <div className="doctor-image">
                    {imgUrl && <img src={imgUrl} alt="image" />}
                    {profilePage && (
                      <Link to={profilePage} className="details-btn">
                        <i className="fas fa-plus"></i>
                      </Link>
                    )}
                  </div>

                  <div className="doctor-content">
                    <h3>
                      <a href="#">{name}</a>
                    </h3>
                    <span>{designation}</span>

                    <ul className="social">
                      {socialMedias.facebook && (
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                      )}
                      {socialMedias.twitter && (
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                      )}
                      {socialMedias.linkedin && (
                        <li>
                          <a href="#">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      )}
                      {socialMedias.instagram && (
                        <li>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>

      <div className="shape3">
        <img
          src="/assets/img/shape/3.png"
          className="wow fadeInLeft"
          alt="image"
        />
      </div>
    </section>
  );
};

export default Staff;
