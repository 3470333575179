import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import axios from 'axios';
import { Link } from 'react-router-dom';

import { SERVICES, INSURANCES } from '../config';

const RightSideComponent = ({ location }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setAPIResponse] = useState(null);

  const setTemporaryMessage = (message) => {
    setAPIResponse(message);

    if (!message.status) {
      setTimeout(() => setAPIResponse(null), 5000);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = ['name', 'email', 'phone', 'insurance', 'age'].reduce(
        (result, _) => ({ ...result, [_]: e.target[_].value }),
        {}
      );
      const response = await axios.post(
        '//ncmd-totalmd.herokuapp.com/totalmdcare/query',
        data
      );

      setTemporaryMessage(response);
    } catch (e) {
      setTemporaryMessage({
        status: false,
        message: 'Request could not be fulfilled.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.$('select').niceSelect();
  }, []);

  return (
    <div class="col-lg-4 col-md-12">
      <aside class="widget-area" id="secondary">
        <section id="side-navlink" class="widget widget_services_list">
          <h3 class="widget-title">More Services</h3>

          <ul>
            {SERVICES.map(({ text, link, external }) => (
              <li key={text}>
                {external ? (
                  <a href={link} target="_blank">
                    {text}{' '}
                  </a>
                ) : (
                  <Link
                    to={`/medical-cares/${link}`}
                    className={classnames({
                      active: location.pathname.includes(link),
                    })}
                  >
                    {text}
                    <i class="flaticon-arrow-pointing-to-right"></i>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </section>

        <section id="more-info" class="widget widget_download">
          <h3 class="widget-title">More Info</h3>

          <ul>
            <li>
              <Link to="/doctor/dr-tanmay-patel-md">
                Our Doctor <i class="far flaticon-doctor"></i>
              </Link>
            </li>
            <li>
              <Link to="/insurance">
                Insurances <i class="far fa-file-alt"></i>
              </Link>
            </li>
            <li>
              <a
                href="https://www.medentmobile.com/portal/index.php?practice_id=E9nVL9rW"
                target="_blank"
              >
                Patient Portal <i class="far fa-file-alt"></i>
              </a>
            </li>
          </ul>
        </section>

        <section
          class={classnames('widget widget_appointment', {
            disable: isLoading,
          })}
        >
          <h3 class="widget-title">Get Some Advice?</h3>
          {apiResponse && apiResponse.status ? (
            <div className="container">
              <h3> Thanks for contacting us!!!</h3>
            </div>
          ) : (
            <form class="appointment-form" onSubmit={submitForm}>
              <div class="form-group">
                <div class="icon">
                  <i class="flaticon-user"></i>
                </div>
                <label>Your Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Name"
                  id="name"
                  name="name"
                  required
                  minLength={3}
                />
              </div>

              <div class="form-group">
                <div class="icon">
                  <i class="flaticon-envelope"></i>
                </div>
                <label>Your Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Email Address"
                  id="email"
                  name="email"
                  required
                />
              </div>

              <div class="form-group">
                <div class="icon">
                  <i class="flaticon-envelope"></i>
                </div>
                <label>Your Phone</label>
                <input
                  type="tel"
                  className="form-control"
                  placeholder="Enter Your Phone"
                  id="phone"
                  name="phone"
                  pattern="[0-9]{6,20}"
                  required
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      'Only digits with minimum 6 and maximum 20 digits'
                    )
                  }
                  onInput={(e) => e.target.setCustomValidity('')}
                />
              </div>

              <div class="form-group">
                <div class="icon">
                  <i class="flaticon-support"></i>
                </div>
                <label>Insurances</label>

                <select required id="insurance-select" name="insurance">
                  <option value=""> --- SELECT --- </option>
                  {[...INSURANCES].map((_) => (
                    <option value={_.text} key={_.text}>
                      {_.text}
                    </option>
                  ))}
                  <option value="other">Other</option>
                </select>
              </div>

              <div class="form-group">
                <div class="icon">
                  <i class="flaticon-support"></i>
                </div>
                <label>Your Age</label>

                <select required id="age-select" name="age">
                  <option value=""> --- SELECT --- </option>
                  {[...Array(85)].map((_, index) => (
                    <option value={index + 15} key={index}>
                      {index + 15}
                    </option>
                  ))}
                </select>
              </div>

              <button class="btn btn-primary">
                Make Appointment <i class="flaticon-right-chevron"></i>
              </button>
            </form>
          )}
        </section>
      </aside>
      {apiResponse && !apiResponse.status && (
        <div aria-live="polite" aria-atomic="true">
          <div class="toast" data-status={apiResponse.status.toString()}>
            <div class="toast-header">
              <strong class="mr-auto">
                {apiResponse.status ? 'Success' : 'Error'}
              </strong>
            </div>
            <div class="toast-body">
              {apiResponse.status
                ? 'We have received your request. We will call you back in 1-2 business day'
                : apiResponse.message}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RightSideComponent;
