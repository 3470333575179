import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { SERVICES } from '../../config';
import RightSideComponent from '../../components/right_side_component';

import '../../scss/cardio_respiratory_health.scss';

const VACCINES = [
  'Coronavirus',
  'Flu (influenza)',
  'Measles',
  'Hepatitis',
  'Shingles',
  'Pneumonia',
  'Tetanus',
  'Meningitis',
  'Chickenpox.',
];

const Vaccinations = ({ location }) => {
  return (
    <section class="services-details-area ptb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <div class="services-details-desc">
              <div class="services-details-image">
                <img src="/assets/img/services/s5_1.jpg" alt="image" />
              </div>

              <h3>Adult Vaccinations/Immunizations</h3>

              <p>
                Here at Total MD Care we recognize the importance of protecting
                against infectious diseases—and the serious, sometimes deadly,
                consequences of not doing so. Every year in the United States,
                thousands of adults (both young and old) become very ill from a
                variety of vaccine-preventable diseases. Following are most
                common diseases that can be vaccinated:
              </p>

              <div class="services-details-features">
                <div class="row">
                  <div class="p-2">
                    <ul class="services-features-list">
                      {VACCINES.map((_) => (
                        <li key={_}>
                          <i class="flaticon-check-mark"></i> {_}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <h3>Professional Medical Service</h3>
              <p>
                Whether appointments are in-person or virtual, our guiding
                principle is to deliver state-of-the-art healthcare, combined
                with sincere interest and compassion, to each and every patient
                we serve. We are using variety of different application to do
                Telemedicine with our patients.
              </p>

              <ul class="wp-block-gallery columns-3">
                <li class="blocks-gallery-item">
                  <figure>
                    <img src="/assets/img/services/s5_1.jpg" alt="image" />
                  </figure>
                </li>

                <li class="blocks-gallery-item">
                  <figure>
                    <img src="/assets/img/services/s5_2.jpg" alt="image" />
                  </figure>
                </li>

                <li class="blocks-gallery-item">
                  <figure>
                    <img src="/assets/img/services/s5_3.jpg" alt="image" />
                  </figure>
                </li>
              </ul>
            </div>
          </div>
          <RightSideComponent location={location} />
        </div>
      </div>
    </section>
  );
};

export default Vaccinations;
