import React from 'react';

import RightSideComponent from '../components/right_side_component';

import '../scss/cardio_respiratory_health.scss';

const CONDITIONS = [
  'Reduce your risk of heart disease by lowering blood pressure and cholesterol. ',
  'Experience the benefits of improved sleep and wake up feeling refreshed. ',
  'Elevate your mood and boost your energy levels.',
  'Improved sexual health. ',
];

const WeightLoss = ({ location }) => {
  return (
    <section class="services-details-area ptb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <div class="services-details-desc">
              <div class="services-details-image">
                <img src="/assets/img/weight-loss.jpg" alt="image" />
              </div>

              <h3>Weight Loss</h3>

              <p>
                Nearly <b>40% of US adults are obese</b>, and Florida is not immune, with 28% of adults struggling with obesity according to the Florida Department of Health. This trend is concerning, as obesity is linked to numerous health issues like heart disease, diabetes, liver disease and kidney disease. At our clinic, we understand weight loss is about more than a number of pounds do you lose. Our program focuses on helping you feel better and improve your overall quality of life.
              </p>
              <p>
                Experience reduced risk of disease, improved heart health, better sleep, and more! We offer personalized plans, nutrition guidance, and a supportive environment to help you achieve your goals. Contact us today for a free consultation and take the first step towards a healthier you!
              </p>
              <p>
                Our weight loss program offers a variety of benefits to help you achieve a healthier and happier you. 
              </p>

              <div class="services-details-features">
                <div class="row">
                  <div class="p-2">
                    <ul class="services-features-list">
                      {CONDITIONS.map((_) => (
                        <li key={_}>
                          <i class="flaticon-check-mark"></i> {_}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <RightSideComponent location={location} />
        </div>
      </div>
    </section>
  );
};

export default WeightLoss;
