import React from 'react';

import { INSURANCES } from '../../config';

const InsuranceIndex = () => {
  return (
    <section className="partner-area ptb-100 bg-f4f9fd">
      <div className="container">
        <div className="section-title">
          <h2>Our Insurance Partners</h2>
          <p>
            Total MD Care accepts many types of health insurance (and is in the
            process of increasing this number). Below is a list of health
            insurance plans we currently accept:{' '}
          </p>
        </div>

        <div className="d-flex justify-content-center customers-partner-list">
          {INSURANCES.map(({ imgUrl, text }) => (
            <div
              className="d-flex align-items-center justify-content-center partner-item m-1"
              key={imgUrl}
            >
              <a href={imgUrl} target="_blank" rel="noopener noreferrer">
                <img src={imgUrl} alt={text} />
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default InsuranceIndex;
