import React from 'react';
import { Link } from 'react-router-dom';

import { SERVICES } from '../config';

import '../scss/main_services.scss';

const MainServices = () => (
  <section className="main-services-area p-5" id="main-services">
    <div className="container col-lg-9">
      <div className="section-title">
        <span>Main Features</span>
        <h2>Our Main Services</h2>
        <p>
          Knowing that many patients find it scary to discuss health issues, our
          goal is to always provide the most compassionate and comprehensive
          evidence-based treatment possible.
        </p>
      </div>

      <div className="d-flex flex-wrap justify-content-center">
        {SERVICES.map(({ external, link, text, desc }) => (
          <div className="">
            <div className="main-services-box">
              <div className="icon">
                <i className="flaticon-doctor"></i>
              </div>
              <h3>
                {external ? (
                  <a href={link} target="_blank">
                    {text}
                  </a>
                ) : (
                  <Link href={link}>{text}</Link>
                )}
              </h3>
              <p>{desc}</p>
            </div>
          </div>
        ))}
      </div>
    </div>

    <div className="shape2">
      <img src="/assets/img/shape/2.png" alt="image" />
    </div>
  </section>
);

export default MainServices;
