import React from 'react';

const UninsuredPatients = () => {
  return (
    <section className="partner-area ptb-100 bg-f4f9fd">
      <div className="container">
        <div className="section-title">
          <h2>Uninsured Patients</h2>
        </div>

        <div className="d-flex justify-content-center customers-partner-list">
          <p>
            We realize that not all of our patients have access to health
            insurance. We feel strongly that this should not be a barrier to
            receiving the top-quality medical care you deserve.
          </p>
          <p>
            To better serve uninsured patients who are paying out of pocket, we
            offer discounted fees for office visits and other services provided
            at Total MD Care. Please contact our clinic at{' '}
            <a href="tel:321-222-3607">321 222 3607</a> for more information.
          </p>
          <p>
            We work closely with near by laboratories for reasonable out of
            pocket cost compare to Urgent Care Clinic or Emergency room visit.
          </p>
        </div>
      </div>
    </section>
  );
};

export default UninsuredPatients;
