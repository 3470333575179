import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { SERVICES } from '../../config';
import RightSideComponent from '../../components/right_side_component';

import '../../scss/cardio_respiratory_health.scss';

const CARDIO_HEALTH_CONDITIONS = [
  'Atrial fibrillation/Flutter',
  'Supraventicular tachycardia',
  'Coronary artery disease',
  'Cardiomyopathies',
  'Heart Failure',
  'Pulmonary Hypertension',
  'Hypertension',
  'Hyperlipidemia',
  'Pulmonary edema',
  'Myocardial Infarction',
  'Valvular diseases',
];

const CARDIO_HEALTH_TIPS = [
  'healthy diet ',
  'proper exercise',
  'stress reduction',
  'weight management',
  'smoking cessation',
];

const CardioRespiratoryHealth = ({ location }) => {
  return (
    <section class="services-details-area ptb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <div class="services-details-desc">
              <div class="services-details-image">
                <img src="/assets/img/services/s4_4.jpg" alt="image" />
              </div>

              <h3>Cardiorespiratory Health</h3>

              <p>
                Many people find it scary to discuss issues related to their
                heart and lungs with their doctor. The staff at Total MD Care is
                dedicated to putting you at ease as we provide top-quality
                treatment for a variety of common but serious cardiorespiratory
                conditions.
              </p>

              <p>
                With more than 17 years of clinical experience as a
                board-certified internist, Dr. Patel firmly believes in the
                value of holistic care when addressing problems of the heart and
                lungs. He works with each patient to develop a comprehensive
                wellness program so they can immediately start enjoying a
                healthier and happier daily life. In addition to assessing the
                results of appropriate bloodwork or other tests, Dr. Patel will
                manage different Cardiovascular health conditions.
              </p>

              <div class="services-details-features">
                <div class="row">
                  <div class="p-2">
                    <ul class="services-features-list">
                      {CARDIO_HEALTH_CONDITIONS.map((_) => (
                        <li key={_}>
                          <i class="flaticon-check-mark"></i> {_}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <h3>Professional Medical Service</h3>
              <p>
                Dr. Patel provides advice both pharmacological and
                non-pharmacological treatments for managing your cardiovascular
                diseases.
              </p>

              <ul class="wp-block-gallery columns-3">
                <li class="blocks-gallery-item">
                  <figure>
                    <img src="/assets/img/services/s4_4.jpg" alt="image" />
                  </figure>
                </li>

                <li class="blocks-gallery-item">
                  <figure>
                    <img src="/assets/img/services/s4_2.jpg" alt="image" />
                  </figure>
                </li>

                <li class="blocks-gallery-item">
                  <figure>
                    <img src="/assets/img/services/s4_3.jpg" alt="image" />
                  </figure>
                </li>
              </ul>

              <h3>
                What are different non-pharmacological modalities of Coronary
                artery disease prevention?
              </h3>

              <div class="services-details-features">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul class="services-features-list">
                      {CARDIO_HEALTH_TIPS.map((_) => (
                        <li key={_}>
                          <i class="flaticon-check-mark"></i> {_}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <RightSideComponent location={location} />
        </div>
      </div>
    </section>
  );
};

export default CardioRespiratoryHealth;
