import React, { useEffect } from 'react';

import '../scss/fun_facts.scss';

const FunFacts = () => {
  useEffect(() => {
    window.createOdoMeter && window.createOdoMeter();
  }, []);
  return (
    <section className="fun-facts-area ptb-100" id="fun-facts-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 col-6">
            <div className="single-fun-facts">
              <div className="icon">
                <i className="flaticon-doctor-1"></i>
              </div>
              <h3>
                <span className="odometer" data-count="4"></span>
              </h3>
              <p>Total Staff</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-3 col-6">
            <div className="single-fun-facts">
              <div className="icon">
                <i className="flaticon-light-bulb"></i>
              </div>
              <h3>
                <span className="odometer" data-count="15"></span>
                <span className="optional-icon">+</span>
              </h3>
              <p>Insurance Plans Accepted</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-3 col-6">
            <div className="single-fun-facts">
              <div className="icon">
                <i className="flaticon-science"></i>
              </div>
              <h3>
                <span className="odometer" data-count="4218"></span>
                <span className="optional-icon">+</span>
              </h3>
              <p>Patients treated</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-3 col-6">
            <div className="single-fun-facts">
              <div className="icon">
                <i className="flaticon-trophy"></i>
              </div>
              <h3>
                <span className="odometer" data-count="15"></span>
                <span className="optional-icon">y</span>
              </h3>
              <p>Experience</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FunFacts;
