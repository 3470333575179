import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { SERVICES } from '../../config';
import RightSideComponent from '../../components/right_side_component';

import '../../scss/cardio_respiratory_health.scss';



const InternalMedicine = ({ location }) => {
  return (
    <section class="services-details-area ptb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <div class="services-details-desc">
              <div class="services-details-image">
                <img src="/assets/img/services/s1_1.jpg" alt="image" />
              </div>

              <h3>Internal Medicine</h3>

              <p>
                An internist is a specially trained primary care physician who
                only treats adult patients. As a board-certified internist with
                over 17 years of experience in both inpatient and outpatient
                medical settings, Dr. Patel sees patients with a wide range of
                health conditions such as upper respiratory infections, high
                cholesterol, back pain, diabetes, allergies, heart disease, skin
                irritations, depression or anxiety, hypertension, sports
                injuries, ear infections, digestive problems, and chronic lung
                disease. Dr. Patel always carefully evaluates all aspects of a
                patient’s current medical status and past history, knowing how
                often one health problem can lead to additional complications.
                Our staff is dedicated to providing comprehensive holistic and
                individualized care for every patient we see, with an emphasis
                on long-term wellness promotion and disease prevention. At Total
                MD Care, your overall health and quality of life truly is our
                passion.
              </p>
            </div>
          </div>
            <RightSideComponent location={location} />
        </div>
      </div>
    </section>
  );
};

export default InternalMedicine;
