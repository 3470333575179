import React from 'react';

import Contact from '../components/contact';
import { STAFF, MY_SKILLS, MY_QUALIFICATIONS } from '../config';

import '../scss/doctors.scss';

const Doctor = () => {
  const doctorDetails = STAFF[0];
  const { facebook, twitter, linkedin, instagram } = doctorDetails.socialMedias;

  return (
    <div className="container col-lg-12 p-0">
      <section class="doctor-details-area ptb-100">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
              <div class="doctor-details-image">
                <img src="/assets/img/doctors/tanmay.jpg" alt="image" />

                <h3>{doctorDetails.name}</h3>
                <span>Internist</span>

                <ul class="social">
                  <li>
                    {facebook && (
                      <a href={facebook} target="_blank">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    )}
                  </li>
                  <li>
                    {twitter && (
                      <a href={twitter}>
                        <i class="fab fa-twitter" target="_blank"></i>
                      </a>
                    )}
                  </li>
                  <li>
                    {linkedin && (
                      <a href={linkedin}>
                        <i class="fab fa-linkedin-in" target="_blank"></i>
                      </a>
                    )}
                  </li>
                  <li>
                    {instagram && (
                      <a href={instagram}>
                        <i class="fab fa-instagram" target="_blank"></i>
                      </a>
                    )}
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-7 col-md-12">
              <div class="doctor-details-desc">
                <h2>Dr. Tanmay Patel as Primary Care Physician.</h2>

                <p>
                  An internist is a specially trained primary care physician who
                  only treats adult patients. As a board-certified internist
                  with over 17 years of experience in both inpatient and
                  outpatient medical settings, Dr. Patel sees patients with a
                  wide range of health conditions such as upper respiratory
                  infections, high cholesterol, back pain, diabetes, allergies,
                  heart disease, skin irritations, depression or anxiety,
                  hypertension, sports injuries, ear infections, digestive
                  problems, and chronic lung disease. Dr. Patel always carefully
                  evaluates all aspects of a patient’s current medical status
                  and past history, knowing how often one health problem can
                  lead to additional complications.
                </p>

                <p>
                  Our staff is dedicated to providing comprehensive holistic and
                  individualized care for every patient we see, with an emphasis
                  on long-term wellness promotion and disease prevention. At
                  Total MD Care, your overall health and quality of life truly
                  is our passion.
                </p>

                <div class="signature-image">
                  {/* <img src="/assets/img/signature.png" alt="image" /> */}
                  <p>- Tanmay Patel</p>
                </div>
              </div>
            </div>
          </div>

          <div class="skill-education-desc">
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <div class="skill-desc">
                  <span class="sub-title">My Skills</span>
                  <h2>I Have Explained about Myself A bit</h2>
                  <p>
                    We are providing all inclusive primary care services. I have
                    vast experience with inpatient and outpatient disease
                    management. I have extensive involvement in managing
                    patients with different medical conditions. We are able to
                    take care of many complex medical problems which require
                    many different specialties.
                  </p>

                  <ul>
                    {MY_SKILLS.map((_) => (
                      <li key={_}>
                        <span>
                          <i class="flaticon-check-mark"></i> {_}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div class="col-lg-6 col-md-12">
                <div class="education-desc">
                  <span class="sub-title">My Education</span>
                  <h2>Here is my 17 Years Qualifications</h2>
                  <p>
                    I have received education both overseas and within the
                    United States. That helps me treat both common and rare
                    diseases across the globe. I was one of the youngest
                    graduates from medical school passed within top 10% of our
                    class.
                  </p>

                  <ul>
                    {MY_QUALIFICATIONS.map((_) => (
                      <li key={_.text}>
                        <div class="icon">
                          <i class="flaticon-doctor"></i>
                        </div>
                        <span>{_.text}</span>
                        {_.subText}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="shape3">
          <img
            src="assets/img/shape/3.png"
            class="wow fadeInLeft"
            alt="image"
          />
        </div>
      </section>
      <Contact />
    </div>
  );
};

export default Doctor;
