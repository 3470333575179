import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { SERVICES } from '../../config';
import RightSideComponent from '../../components/right_side_component';

import '../../scss/cardio_respiratory_health.scss';

const GERIATRIC_CONDITIONS = [
  'Diabetes',
  'Arthritis',
  'Heart disease',
  'Hypertension',
  'Balance and stability problems',
  'Persistent coughing',
  'Unexpected weight loss',
  'Depression or aAnxiety',
  'Chronic lung disease',
  'Cognitive impairments including memory loss',
  'Urinary Incontinence',
];

const CARDIO_HEALTH_TIPS = [
  'healthy diet ',
  'proper exercise',
  'stress reduction',
  'weight management',
  'smoking cessation',
];

const InternalMedicine = ({ location }) => {
  return (
    <section class="services-details-area ptb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <div class="services-details-desc">
              <div class="services-details-image">
                <img src="/assets/img/services/s2_3.jpg" alt="image" />
              </div>

              <h3>Geriatric Medical Care</h3>

              <p>
                Clinical treatment continues to improve for older patients with
                chronic, acute, or multiple health conditions. As a
                board-certified internist with over 17 years of experience, Dr.
                Patel can expertly diagnose and treat a wide variety of medical
                issues of special concern to older primary care patients.
              </p>

              <p>
                Following are most common disease that older patients are
                suffering from it and we will love to you help you control those
                much better.
              </p>

              <div class="services-details-features">
                <div class="row">
                  <div class="p-2">
                    <ul class="services-features-list">
                      {GERIATRIC_CONDITIONS.map((_) => (
                        <li key={_}>
                          <i class="flaticon-check-mark"></i> {_}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <h3>Professional Medical Service</h3>
              <p>
                At Total MD Care we carefully assess each patient’s unique
                physical, emotional, and social needs and goals to formulate a
                comprehensive treatment plan. With a strong emphasis on holistic
                health promotion and disease prevention, our ultimate goal is
                for you to enjoy as much daily vitality, mobility, and
                independence as possible. This perspective, we believe, is key
                to a happy and rewarding life at any age.
              </p>

              <ul class="wp-block-gallery columns-3">
                <li class="blocks-gallery-item">
                  <figure>
                    <img src="/assets/img/services/s2_2.jpg" alt="image" />
                  </figure>
                </li>

                <li class="blocks-gallery-item">
                  <figure>
                    <img src="/assets/img/services/s2_3.jpg" alt="image" />
                  </figure>
                </li>

                <li class="blocks-gallery-item">
                  <figure>
                    <img src="/assets/img/services/s2_1.jpg" alt="image" />
                  </figure>
                </li>
              </ul>
            </div>
          </div>
          <RightSideComponent location={location} />
        </div>
      </div>
    </section>
  );
};

export default InternalMedicine;
