import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { INSURANCES } from '../config';

import '../scss/contact.scss';

const Contact = () => {
  const [consent, setConsent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setAPIResponse] = useState(null);

  const setTemporaryMessage = (message) => {
    setAPIResponse(message);

    if (!message.status) {
      setTimeout(() => setAPIResponse(null), 5000);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = ['name', 'email', 'phone', 'insurance', 'age'].reduce(
        (result, _) => ({ ...result, [_]: e.target[_].value }),
        {}
      );
      const response = await axios.post(
        '//ncmd-totalmd.herokuapp.com/totalmdcare/query',
        data
      );

      setTemporaryMessage(response);
    } catch (e) {
      setTemporaryMessage({
        status: false,
        message: 'Request could not be fulfilled.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.$('select').niceSelect();
  }, []);

  return (
    <section
      id="appointment-area"
      className="appointment-area ptb-100 jarallax"
      data-jarallax='{"speed": 0.3}'
    >
      <div className="container">
        <div
          className={`appointment-content ${isLoading ? 'disable' : ''}`.trim()}
        >
          <span className="sub-title">Book Appointment</span>

          {apiResponse && apiResponse.status ? (
            <div className="container">
              <h3> Thanks for contacting us!!!</h3>
            </div>
          ) : (
            <>
              <h2>We are here for you</h2>
              <form onSubmit={submitForm}>
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <div className="icon">
                        <i className="flaticon-user"></i>
                      </div>
                      <label>Your Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Name"
                        id="name"
                        name="name"
                        required
                        minLength={3}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <div className="icon">
                        <i className="flaticon-envelope"></i>
                      </div>
                      <label>Your Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email Address"
                        id="email"
                        name="email"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <div className="icon">
                        <i className="flaticon-support"></i>
                      </div>
                      <label> Your Insurance</label>

                      <select required id="insurance-select" name="insurance">
                        <option value=""> --- SELECT --- </option>
                        {[...INSURANCES].map((_) => (
                          <option value={_.text} key={_.text}>
                            {_.text}
                          </option>
                        ))}
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <div className="icon">
                        <i className="flaticon-support"></i>
                      </div>
                      <label> Your Age</label>

                      <select required id="age-select" name="age">
                        <option value=""> --- SELECT --- </option>
                        {[...Array(85)].map((_, index) => (
                          <option value={index + 16} key={index}>
                            {index + 16}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <div className="icon">
                        <i className="flaticon-phone-call"></i>
                      </div>
                      <label>Your Phone</label>
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Enter Your Phone"
                        id="phone"
                        name="phone"
                        pattern="[0-9]{6,20}"
                        required
                        onInvalid={(e) =>
                          e.target.setCustomValidity(
                            'Only digits with minimum 6 and maximum 20 digits'
                          )
                        }
                        onInput={(e) => e.target.setCustomValidity('')}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12" >            
                    <div class="buy-checkbox-btn">
                      <div class="item" style={{ display: 'flex', alignItems: 'flex-start', marginTop: 30 }}>
                        <input checked={consent} class="inp-cbx" id="cbx" type="checkbox" onChange={() => setConsent(!consent)}/>
                        <label class="cbx" for="cbx" style={{ marginLeft: 10 }}>                          
                          <span>By submitting this form you agree to receive SMS from <b>Total MD Care</b>. Carrier and Data rates may apply. Message frequency may vary Reply STOP at any time to end messaging or Reply Help for more information.</span>
                        </label>
                      </div>
                      <div>
                        <a target='_blank' href="/privacy-policy" style={{ textDecoration: 'underline' }}>Click here to read Privacy Policy.</a>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="submit-btn">
                      <button className="btn btn-primary" type="submit" disabled={!consent} style={{ opacity: consent ? 1 : 0.5 }}>
                        Make Appointment{' '}
                       
                      </button>
                    </div>
                  </div>

                </div>
              </form>
            </>
          )}
        </div>
      </div>
      {apiResponse && !apiResponse.status && (
        <div aria-live="polite" aria-atomic="true">
          <div class="toast" data-status={apiResponse.status.toString()}>
            <div class="toast-header">
              <strong class="mr-auto">
                {apiResponse.status ? 'Success' : 'Error'}
              </strong>
            </div>
            <div class="toast-body">
              {apiResponse.status
                ? 'We have received your request. We will call you back in 1-2 business day'
                : apiResponse.message}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Contact;
