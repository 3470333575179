import React from 'react';
import { Link } from 'react-router-dom';

import {
  NAV_BAR,
  WORKING_HOURS,
  SERVICES,
  SOCIAL_MEDIA,
  ADDRESSES,
  PHONE_NUMBERS,
} from '../config';

import '../scss/footer.scss';

const Footer = ({ location }) => {
  return (
    <section className="footer-area" id="footer-area">
      <div className="container col-lg-9">
        {false && (
          <div className="subscribe-area">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="newsletter-content">
                  <h2>Join Our Newsletter</h2>
                  <p>
                    To Get the latest information about health and clinical
                    advances, please subscribe to monthly newsletter
                  </p>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <form className="newsletter-form" data-toggle="validator">
                  <input
                    type="email"
                    className="input-newsletter"
                    placeholder="Enter your email"
                    name="EMAIL"
                    required
                    autocomplete="off"
                  />

                  <button type="submit">
                    Subscribe <i className="fas fa-paper-plane"></i>
                  </button>
                  <div id="validator-newsletter" className="form-result"></div>
                </form>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <div className="logo">
                <Link to="#">
                  <img src="/assets/img/logo.png" alt="image" />
                </Link>
                <p>Your Health is Our Passion.</p>
              </div>

              <ul className="social">
                {SOCIAL_MEDIA.map((sMedia) => (
                  <li key={sMedia.type}>
                    <a href={sMedia.link} target="_blank">
                      <i className={`fab fa-${sMedia.type}`}></i>
                    </a>
                  </li>
                ))}
              </ul>
              <br />
              <div className="address">
                {ADDRESSES.map((_, index) => (
                  <p
                    key={index}
                    dangerouslySetInnerHTML={{ __html: _.address }}
                  />
                ))}
              </div>

              <ul className="social">
                <li>Email: contact@totalmdcare.com</li>
                <li>Phone: {PHONE_NUMBERS[0]}</li>
                <li>Fax: {PHONE_NUMBERS[1]}</li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="single-footer-widget pl-5">
              <h3>Services</h3>
              <ul className="departments-list">
                {SERVICES.map(({ text, external, link }) => (
                  <li key={text}>
                    <Link to={`/medical-cares/${link}`}>{text}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="single-footer-widget pl-5">
              <h3>Links</h3>

              <ul className="links-list">
                {NAV_BAR.map(({ text, link, external }) => (
                  <li key={text}>
                    {external ? (
                      <a href={link} target="_blank">
                        {text}
                      </a>
                    ) : (
                      <Link to={link}>{text}</Link>
                    )}
                  </li>
                ))}
                  <li>
                    <Link to={'/privacy-policy'}>Privacy Policy</Link>                 
                  </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <h3>Opening Hours</h3>

              <ul className="opening-hours">
                {WORKING_HOURS.map((hour) => (
                  <li key={hour.day}>
                    {hour.day} <span>{hour.time}</span>
                  </li>
                ))}
                <li>
                  Saturday <span>Closed</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="copyright-area">
          <p>
            <i className="far fa-copyright"></i> Design & Developed by{' '}
            <Link to="https://envytheme.com" target="_blank">
              TOTALMDCARE
            </Link>
          </p>
        </div>
      </div>
      <div class="go-top">
        <i class="fas fa-chevron-up"></i>
      </div>
    </section>
  );
};

export default Footer;
