export const SOCIAL_MEDIA = [
  {
    link: 'https://www.instagram.com/totalmdcare/',
    type: 'instagram',
    text: 'Instagram',
  },
  {
    link: 'https://www.facebook.com/totalmdcare',
    type: 'facebook-f',
    text: 'Facebook',
  },
  {
    link: 'https://www.linkedin.com/company/naturalclinicmd',
    type: 'linkedin-in',
    text: 'LinkedIn',
  },
  {
    link: 'https://www.twitter.com/totalmdcare/',
    type: 'twitter',
    text: 'Twitter',
  },
];

export const DEPARTMENTS = [
  {
    link: '/',
    text: 'Surgery and Radiology',
  },
  {
    link: '/',
    text: 'Family',
  },
  {
    link: '/',
    text: 'Our Doctors',
  },
  {
    link: '/',
    text: "Woman's Health",
  },
  {
    link: '/',
    text: 'Blog',
  },
];

export const SPECIALIZATION = [
  {
    link: '/',
    text: 'Optician',
  },
  {
    link: '/',
    text: 'Pediatrics',
  },
  {
    link: '/',
    text: 'Dermatology',
  },
  {
    link: '/',
    text: 'Doctors',
  },
];

export const WORKING_HOURS = [
  {
    day: 'Monday',
    time: '9.00AM - 05.00PM',
  },
  {
    day: 'Tuesday',
    time: '9.00AM - 05.00PM',
  },
  {
    day: 'Wednesday',
    time: '9.00AM - 05.00PM',
  },
  {
    day: 'Thursday',
    time: '9.00AM - 05.00PM',
  },
  {
    day: 'Friday',
    time: '9.00AM - 05.00PM',
  },
];

export const EMAILS = ['contact@totalmdcare.com'];

export const PHONE_NUMBERS = ['(321) 222 3607', ' (888) 849 6158'];

export const SERVICES = [
  {
    link: 'internal-medicine',
    text: 'Internal medicine',
    desc: 'Managing different acute, subacute and chronic medical diseases',
  },
  {
    link: 'geriatric-medical-care',
    text: 'Geriatric medical care',
    desc: 'formulate a comprehensive treatment plan that emphasizes holistic health promotion and disease prevention',
  },
  {
    link: 'telemedicine-appointments',
    text: 'Telemedicine appointments',
    desc: 'Providing urgent appointment to address your immediate concerns through video appointments',
  },
  {
    link: 'cardio-respiratory-health',
    text: 'Cardiovascular health',
    desc: 'Provide the most compassionate and comprehensive evidence-based treatment',
  },
  {
    link: 'vaccination-and-immunizations',
    text: 'Vaccinations/immunizations',
    desc: 'Providing COVID 19 Immunization through state regulation* (* when available by state)',
  },
  // {
  //   link: 'https://www.naturalclinicmd.com',
  //   external: true,
  //   text: 'Medical Marijuana',
  // },
];

export const ADDRESSES = [
  {
    address: `4340 S Hopkins Ave<br/>
Titusville, FL 32780`,
  },
  {
    address: `4195 US - 1, Unit 101<br/>
  Rockledge, FL 32955`,
  },
];

export const NAV_BAR = [
  {
    link: '/',
    text: 'Home',
  },
  {
    link: '/medical-cares/',
    text: 'Medical Services',
    subMenu: [...SERVICES],
  },
  {
    link: '/weight-loss',
    text: "Weight Loss",
  },
  {
    link: '/covid-19',
    text: 'Covid-19',
    disabled: true,
    subMenu: [
      {
        link: '/disease-information',
        text: 'Disease information',
      },
      {
        link: '/clinic-precautions',
        text: 'Clinic precautions',
      },
      {
        link: '/home-quarantine',
        text: 'Home quarantine',
      },
    ],
  },
  {
    link: '/insurance',
    text: 'Insurance',
    subMenu: [
      {
        text: 'Insurance accepted',
        link: '/patients-with-insurance',
      },
      {
        text: 'Uninsured Patients',
        link: '/patients-without-insurance',
      },
    ],
  },
  {
    link: '/staff',
    text: 'Staff',
    subMenu: [] || [
      {
        text: 'Our Medical Director and treatment philosophy',
        link: '/director-message',
      },
      {
        text: 'Our Clinic Team (need to add information)',
        link: '/whole-team',
      },
    ],
  },
  {
    link: 'https://naturalclinicmd.md-hq.com',
    text: 'Patient Portal',
    external: true,
  },
].filter((_) => !_.disabled);

export const INSURANCES = [
  {
    text: 'Florida Blue',
    imgUrl: '/assets/img/insurances/flblue.jpeg',
  },
  {
    text: 'Florida Health Care Plans',
    imgUrl: '/assets/img/insurances/flhealthcare.jpg',
  },
  { text: 'Wellcare', imgUrl: '/assets/img/insurances/wellcare.png' },
  { text: 'Oscar', imgUrl: '/assets/img/insurances/oscar.jpeg' },
  { text: 'Advent Health', imgUrl: '/assets/img/insurances/adventhealth.jpg' },
  { text: 'Cigna', imgUrl: '/assets/img/insurances/cigna.png' },
  { text: 'Atena', imgUrl: '/assets/img/insurances/atena.png' },
  { text: 'Medicare', imgUrl: '/assets/img/insurances/medicare.png' },
  { text: 'Humana', imgUrl: '/assets/img/insurances/humana.png' },
  { text: 'Care Plus', imgUrl: '/assets/img/insurances/care_plus.jpg' },
  { text: 'Health First', imgUrl: '/assets/img/insurances/health_first.png' },
  {
    text: 'Sunshine Health Care',
    imgUrl: '/assets/img/insurances/sunshine_health_care.jpeg',
  },
  { text: 'Freedom', imgUrl: '/assets/img/insurances/freedom.png' },
  {
    text: 'United Health Care',
    imgUrl: '/assets/img/insurances/uhc_commercial.png',
  },
  {
    text: 'Health Plans Inc',
    imgUrl: '/assets/img/insurances/hpi.jpeg',
  },
  {
    text: 'Simply Health Care',
    imgUrl: '/assets/img/insurances/simply_healthcare.png',
  },
  {
    text: 'Medicaid',
    imgUrl: '/assets/img/insurances/medicad.png',
  },
  {
    text: 'Parrish Health Network',
    imgUrl: '/assets/img/insurances/parrish.png',
  },
  {
    text: 'Devoted Health',
    imgUrl: '/assets/img/insurances/devoted_health.svg',
  },
].sort((a, b) => a.text.localeCompare(b.text));

export const STAFF = [
  {
    name: 'Dr. Tanmay Patel',
    designation: 'Doctor',
    imgUrl: '/assets/img/doctors/tanmay.jpg',
    socialMedias: {},
    profilePage: '/doctor/dr-tanmay-patel-md',
  },
  {
    name: 'Sonal Patel',
    designation: 'Nurse Practitioner',
    // imgUrl: '/assets/img/doctors/sonal.jpg',
    socialMedias: {},
  },
  {
    name: 'Asmi Kessler',
    designation: 'Office Manager',
    // imgUrl: '/assets/img/doctors/asmi.jpg',
    socialMedias: {},
  },
  {
    name: 'Kassandra',
    designation: 'Medical Assistant',
    // imgUrl: '/assets/img/doctors/kassandra.png',
    socialMedias: {},
  },
];

export const MY_SKILLS = [
  'General Physical Examination',
  'Cardiac Arrythymia',
  'Hypertension',
  'Diabetes',
  'Coronary Artery Disease',
  'Pulmonary Diseases',
  'Asthma Exacerbation',
  'Hyperlipidemia',
  'Cancer Screening',
  'Immunization',
  'Hypothyroidism',
  'Psychiatric Illnesses',
  'Arthritis',
  'Gastrointestinal disorders',
];

export const MY_QUALIFICATIONS = [
  {
    text: 'Internal Medicine Residency',
    subText: 'Jamaica Hospital Medical Center (2010  -  2013)',
  },
  {
    text: 'Master of Public Health',
    subText: 'University of South Florida (2005 - 2007)',
  },
  {
    text: 'Bachelor of Medicine, Bachelor of Surgery',
    subText: 'Pramukh Swami Medical College (1998 - 2003)',
  },
  {
    text: 'Certification',
    subText: 'American Board of Internal Medicine (2013-2022)',
  },
];
