import React from 'react';

import '../scss/about.scss';

const TEXT = [
  'Same or next day appointment available',
  '17 years of healthcare experience',
  'Expert chronic disease management team',
  'Providing inpatient and outpatient services',
];

const Skills = () => (
  <ul>
    {TEXT.map((_) => (
      <li key={_}>
        <i className="flaticon-check-mark"></i>
        {_}
      </li>
    ))}
  </ul>
);

const About = () => (
  <section className="about-area" id="about-area">
    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-lg-6 col-md-12 p-0">
          <div className="about-image">
            <img src="/assets/img/about-img1.jpg" alt="image" />
          </div>
        </div>

        <div className="col-lg-6 col-md-12 p-0">
          <div className="about-content">
            <span>About Us</span>
            <h2>Quick Glance at Total MD Care</h2>
            <p>
              Our staff at Total MD Care is dedicated to providing excellent
              patient-centered primary care services for adults living in
              Brevard County. We are always available to help our patients.
            </p>

            <Skills />

            <p>
              <b>Our philosophy is simple: Your Health is Our Passion.</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default About;
